var mqm = window.matchMedia('(min-width: 992px)');
$(function() {
  if (mqm.matches) {
    if ($('.page-header.page-header-fixed').length) {
      $('.submenu').scrollToFixed({
        marginTop: $('.page-top').outerHeight()
      });

      $('.menu').scrollToFixed({
        marginTop: $('.page-top').outerHeight()
      });
      $('html:not(.ie9) .usr-view__img-container, html:not(.ie9) .usr-view__data-container').scrollToFixed({
        marginTop: $('.page-top').outerHeight() + $('.navbar').outerHeight()
      });
    } else {
      if (($( window ).height() + $('.page-header').outerHeight(true) + $('.page-bar').outerHeight(true) + $('.menu').outerHeight(true)) < $( document ).height() ) {
        $('.menu').scrollToFixed();
      }
    }
  }
});

if($('.infinite-paginator-button-wrapper').length) {
  $(document).on('click', '.infinite-paginator-button-wrapper a', function(e){

    var container =  $(this).closest('.infinite-paginator-content-wrapper');
    if($(this).data('container')){
      var container =  $('.' + $(this).data('container'));
    }

    $(this).closest('.infinite-paginator-button-wrapper').remove();

    var listURL = $(this).attr('href');
    $.ajax(
    {
      url : listURL,
      type: "GET",
      success:function(data, textStatus, jqXHR)
      {
        container.append(data);
        $('a:external, a[rel=external]').attr('target', '_blank').addClass('external').attr('rel', 'external');
        $('a:download, a[rel=document]').attr('target', '_blank').addClass('document').attr('rel', 'document');
      },
      error: function(jqXHR, textStatus, errorThrown)
      {

      }
    });
    e.preventDefault(); //STOP default action
  });
}

$(document).on('click', '.actionDelete', function(e){
   $("#deleteActionModal").find('a.actionDo').attr('href', $(this).attr('href'));
});

$(document).on('click', '#searchContainerTrigger', function(e){
  if ($(".search-form-container").hasClass('hidden')) {
    $(".search-form-container").removeClass('hidden');
  } else {
    $(".search-form-container").addClass('hidden');
  }
  e.preventDefault();
  return false;
 });



$('.extended-list--tasks input[type="checkbox"], .tasks-list input[type="checkbox"]').on('click', function(){
  $(this).closest('form').submit();
});

// llistat 2 columnes
$('.list-2cols, .duesCol').each(function(){
   $(this).find('li:even').addClass('even');
});

$('.textbox *:last-child').addClass('last');


// --------
// Menú desplegable en mòbil
// --------
//prevenim click al menú principal amb submenús
$( ".mobile-menu__list .has-sub-nav a.menu-item" ).on( "click", function(event) {
  event.preventDefault();
  event.stopPropagation();
  $(this).parent('li').find('.sub-nav').fadeIn();
  return false;
});

$( ".mobile-menu__list button.tancar" ).on( "click", function(event) {
  event.preventDefault();
  event.stopPropagation();
  $(this).parent('.sub-nav').fadeOut();
  return false;
});

// $('body').on('click', '.responsive-toggler',function(){
//   $(".mobile-menu").toggle();
// });

// --------
// Megamenu (https://github.com/adobe-accessibility/Accessible-Mega-Menu/)
// --------

$(".menu-horitzontal nav").accessibleMegaMenu({
  /* prefix for generated unique id attributes, which are required
     to indicate aria-owns, aria-controls and aria-labelledby */
  uuidPrefix: "accessible-megamenu",

  /* css class used to define the megamenu styling */
  menuClass: "nav-menu",

  /* css class for a top-level navigation item in the megamenu */
  topNavItemClass: "nav-item",

  /* css class for a megamenu panel */
  panelClass: "sub-nav",

  /* css class for a group of items within a megamenu panel */
  panelGroupClass: "sub-nav-group",

  /* css class for the hover state */
  hoverClass: "hover",

  /* css class for the focus state */
  focusClass: "focus",

  /* css class for the open state */
  openClass: "open"
});

(function() {

  var time = 600,
    timer;

  function handlerIn() {
    clearTimeout(timer);
    $('.sub-nav .intro').stop(true).css('opacity', 0).hide();
  }
  function handlerOut() {
    //if($('elemFoo').css('opacity') == 0) {
      timer = setTimeout(function() {
        $('.sub-nav .intro').css('opacity', 1).fadeIn();
      }, time);

  }

  $(".sub-nav-group").hover(handlerIn, handlerOut);

}());

//prevenim click al menú principal amb submenús
// comentar aquesta funció si es decideix utilitzar menú amb hover!
$( ".nav-item a[aria-haspopup]" ).on( "click", function(event) {
  event.preventDefault();
  event.stopPropagation();
  return false;
});

$( ".sub-nav .tancar" ).on( "click", function(event) {
  event.preventDefault();
  event.stopPropagation();
  $('.nav-item a').removeClass('open focus').attr('aria-expanded', 'false');
  $('.sub-nav').removeClass('open').attr('aria-expanded', 'false');


});

// calendari agenda portada
if ($('#agendaCalendar').length) {
  $(document).on('click', '#agendaCalendar table.cal .cal__arrow a', function(e){

    var agendaURL = $(this).attr('href') + '/calendar';
    $('table.cal, table.cal .cal__arrow a').css('cursor', 'progress !important');
    $.ajax(
    {
      url : agendaURL,
      type: "GET",
      success:function(data, textStatus, jqXHR)
      {
        $('#agendaCalendar').html(data);
        $('[data-toggle="popover"]').popover();
        $('#agendaCalendar').fadeIn();
      },
      error: function(jqXHR, textStatus, errorThrown)
      {

      }
    });
    e.preventDefault(); //STOP default action

  });
}

// alertes de portada
$('.home .alertbox .alertbox__close').on('click', function(){
   if (Cookies) {
     var now = new Date();
     Cookies.set('alertbox_closed_on', now.getTime());
   }
});

if ($(".flashMessenger.alert").length > 0) {
  $(".flashMessenger.alert").alert(
    window.setTimeout(function() {
      $(".flashMessenger.alert").alert('close');
    }, 10000)
  );
}

// enquestes
if ($('.pollcontainer').length) {

  var hashFingerPrint = '';
  new Fingerprint2().get(function(result, components){
    hashFingerPrint = result;
  });
  var userPrivateIp = '';
  getUserIP(function(ip){
      userPrivateIp = ip;
  });

  setTimeout(initPolls, 1000);

  function initPolls() {

    $('.pollcontainer').each(function(){

      var enquestaId = $(this).attr('data-enquesta');
      var pollContainer = $(this);

      $.ajax(
      {
        url : pollsURL + '/can-user-vote/' + enquestaId,
        data: {fingerprint: hashFingerPrint, privateIp: userPrivateIp},
        type: "POST",
        success:function(data, textStatus, jqXHR)
        {
          if (data == '1') {
            pollContainer.find('.pollcontainer__form').fadeIn('fast');
          } else {
            pollContainer.find('.pollcontainer__results').fadeIn('fast');
          }
        },
        error: function(jqXHR, textStatus, errorThrown)
        {

        }
      });

      $(this).find('input[type="submit"], button[type="submit"]').on('click', function(e){
        e.preventDefault();
        new Fingerprint2().get(function(result, components){
          hashFingerPrint = result;
          var form = pollContainer.find('form');
          form.append('<input type="hidden" name="fingerprint" value="' + hashFingerPrint + '" />');
          form.append('<input type="hidden" name="privateIp" value="' + userPrivateIp + '" />');
          form.submit();
        });
      })
    });
  }
}

/**
 * Get the user IP throught the webkitRTCPeerConnection
 * @param onNewIP {Function} listener function to expose the IP locally
 * @return undefined
 */
function getUserIP(onNewIP) { //  onNewIp - your listener function for new IPs
  //compatibility for firefox and chrome
  var myPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;

  if(typeof myPeerConnection == "undefined") {
    onNewIP = '';
    return;
  }

  var pc = new myPeerConnection({
    iceServers: []
  }),
  noop = function() {},
  localIPs = {},
  ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g,
  key;

  function iterateIP(ip) {
    if (!localIPs[ip]) onNewIP(ip);
    localIPs[ip] = true;
  }

   //create a bogus data channel
  pc.createDataChannel("");

  // create offer and set local description
  pc.createOffer().then(function(sdp) {
    sdp.sdp.split('\n').forEach(function(line) {
      if (line.indexOf('candidate') < 0) return;
      line.match(ipRegex).forEach(iterateIP);
    });

    pc.setLocalDescription(sdp, noop, noop);
  });

  //listen for candidate events
  pc.onicecandidate = function(ice) {
    if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
    ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
  };
}



$.expr[':'].exec = function(obj) {
    'use strict';
    try {
        if (obj.href) {
            return obj.href.match(/\.exe$/) || obj.href.match(/^[cf]\:\\/);
        }
        return false;
    } catch (e) {
        return false;
    }
};

$('a:exec').bind('click', function(event){
    event.preventDefault();
    var href = $(this).attr('href');

    var ua = window.navigator.userAgent;
    if (ua.indexOf("Trident") > 0){
        exec(href);
    } else {
        alert('Aquesta opció no està disponible en aquest navegador');
    }
    return false;
});


function exec(cmdline, params) {
  var fso = new ActiveXObject("Scripting.FileSystemObject");
  fileExist = fso.FileExists(cmdline);
  if (!fileExist) {
    alert('No s\'ha trobat el fitxer');
  }
  else {
    var shell = new ActiveXObject( "WScript.Shell" );
    if (params) {
        params = ' ' + params;
    }
    else {
        params = '';
    }
    shell.Run('"' + cmdline + '"' + params);
  }
}


var initPhotoSwipeFromDOM = function(gallerySelector) {
    // parse slide data (url, title, size ...) from DOM elements
    // (children of gallerySelector)
    var parseThumbnailElements = function(el) {
        var thumbElements = el.childNodes,
            numNodes = thumbElements.length,
            items = [],
            figureEl,
            linkEl,
            size,
            item;

        for(var i = 0; i < numNodes; i++) {

            figureEl = thumbElements[i]; // <figure> element

            // include only element nodes
            if(figureEl.nodeType !== 1) {
                continue;
            }

            linkEl = figureEl.children[0]; // <a> element
            size = linkEl.getAttribute('data-size').split('x');

            // create slide object
            item = {
                src: linkEl.getAttribute('href'),
                w: parseInt(size[0], 10),
                h: parseInt(size[1], 10)
            };



            if(figureEl.children.length > 1) {
                // <figcaption> content
                item.title = figureEl.children[1].innerHTML;
            }

            if(linkEl.children.length > 0) {
                // <img> thumbnail element, retrieving thumbnail url
                item.msrc = linkEl.children[0].getAttribute('src');
            }

            item.el = figureEl; // save link to element for getThumbBoundsFn
            items.push(item);
        }

        return items;
    };

    // find nearest parent element
    var closest = function closest(el, fn) {
        return el && ( fn(el) ? el : closest(el.parentNode, fn) );
    };

    // triggers when user clicks on thumbnail
    var onThumbnailsClick = function(e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : e.returnValue = false;

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function(el) {
            return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
        });

        if(!clickedListItem) {
            return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
            childNodes = clickedListItem.parentNode.childNodes,
            numChildNodes = childNodes.length,
            nodeIndex = 0,
            index;

        for (var i = 0; i < numChildNodes; i++) {
            if(childNodes[i].nodeType !== 1) {
                continue;
            }

            if(childNodes[i] === clickedListItem) {
                index = nodeIndex;
                break;
            }
            nodeIndex++;
        }



        if(index >= 0) {
            // open PhotoSwipe if valid index found
            openPhotoSwipe( index, clickedGallery );
        }
        return false;
    };

    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    var photoswipeParseHash = function() {
        var hash = window.location.hash.substring(1),
        params = {};

        if(hash.length < 5) {
            return params;
        }

        var vars = hash.split('&');
        for (var i = 0; i < vars.length; i++) {
            if(!vars[i]) {
                continue;
            }
            var pair = vars[i].split('=');
            if(pair.length < 2) {
                continue;
            }
            params[pair[0]] = pair[1];
        }

        if(params.gid) {
            params.gid = parseInt(params.gid, 10);
        }

        return params;
    };

    var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
        var pswpElement = document.querySelectorAll('.pswp')[0],
            gallery,
            options,
            items;

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {

            // define gallery index (for URL)
            galleryUID: galleryElement.getAttribute('data-pswp-uid'),

            getThumbBoundsFn: function(index) {
                // See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect();

                return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
            }

        };

        // PhotoSwipe opened from URL
        if(fromURL) {
            if(options.galleryPIDs) {
                // parse real index when custom PIDs are used
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for(var j = 0; j < items.length; j++) {
                    if(items[j].pid == index) {
                        options.index = j;
                        break;
                    }
                }
            } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1;
            }
        } else {
            options.index = parseInt(index, 10);
        }

        // exit if index not found
        if( isNaN(options.index) ) {
            return;
        }

        if(disableAnimation) {
            options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
    };

    // loop through all gallery elements and bind events
    var galleryElements = document.querySelectorAll( gallerySelector );

    for(var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute('data-pswp-uid', i+1);
        galleryElements[i].onclick = onThumbnailsClick;
    }

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    var hashData = photoswipeParseHash();
    if(hashData.pid && hashData.gid) {
        openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
    }
};

// execute above function
initPhotoSwipeFromDOM('.gallery');


function scrollToAnchor(hash) {
    var target = $(hash);
        //headerHeight = $(".scroll-to-fixed-fixed").height() + 5; // Get fixed header height
    var headerHeight = 51;

    target = target.length ? target : $('[name=' + hash.slice(1) +']');
    console.log(target);
    if (target.length)
    {
        console.log('scroller');
        $('html,body').animate({
            scrollTop: target.offset().top - headerHeight
        }, 100);
        return false;
    }
}

if(window.location.hash) {
    scrollToAnchor(window.location.hash);
}


$("a[href*=\\#]:not([href=\\#]):not(.accordion-toggle)").click(function()
{
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
        || location.hostname == this.hostname)
    {

        scrollToAnchor(this.hash);
    }
});

$( "a#cercar" ).on( "click", function() {
    $( ".cercadorDesplegable" ).slideDown( "slow", function() {
      $( "#campcercar" ).focus();
    });
    return false;
});
$( ".cercar-contenidor .tancar" ).on( "click", function() {
    $( ".cercadorDesplegable" ).slideUp( "slow", function() {

    });
});

if ($('div.menu-vertical').length)  {
    $( ".menu-vertical .has-sub-nav" ).each(function( index ) {
        var menulink = $(this).children("a");
        var menubox = $(this).children("div.sub-nav");
        menulink.attr('aria-hidden', true);
        menulink.attr('aria-controls', 'box_expandir_' + index);
        menulink.attr('id', 'menu_expandir_' + index);
        menubox.attr('aria-expanded', false);
        menubox.attr('aria-hidden', true);
        menubox.attr('id', 'box_expandir_' + index);
        menubox.attr('aria-labelledby', 'menu_expandir_' + index);
    });
    $( ".menu-vertical .has-sub-nav>a" ).on( "click", function(e) {
        e.preventDefault();
        var menubox = $(this).next("div.sub-nav");
        if (menubox.hasClass("open")) {
           menubox.removeClass('open');
           menubox.attr('aria-expanded', false);
           menubox.attr('aria-hidden', true);
           $(this).attr('aria-expanded', false);
           $(this).removeClass('open');
        } else {
            menubox.addClass('open');
            menubox.attr('aria-expanded', true);
            menubox.attr('aria-hidden', false);
            $(this).attr('aria-expanded', true);
            $(this).addClass('open');

        }
        return false;
    });

}

var options = {valueNames:['appName']};
  //var appsList = new List('appsList', options);

  var appsList = new List('llistaAppsCerca', options);

  jQuery('.appSearch').on("keyup",function(){
      var cerca = $(this).val();
      appsList.search(cerca);
      if(cerca != ''){
          $('#appsList .caixaCategoria').hide();
          $('#appsList .resultatsCerca').show();
      } else {
          $('#appsList .caixaCategoria').show();
          $('#appsList .resultatsCerca').hide();
      }
  });


$(document).on('click', '.app', function(e){
    e.preventDefault();
    var data = [];
    var hasUser = $(this).hasClass('user') ? true : false;
    var hasPassword = $(this).hasClass('password') ? true : false;
    var hasIP = $(this).hasClass('ip') ? true : false;
    var url = $(this).attr("href");
    window.open('/media/php/postApp.php?ip=' + hasIP + '&password=' + hasPassword + '&user=' + hasUser + '&url=' + url);
});

// Afegit perquè funcioni el menú.
$(function() {
  $('.menu-toggler').on('click', function() {
    $('.mobile-menu').toggle();
    $('html').toggleClass('mobile-menu-open');
  });
});

// Galeria
$(function() {
	$('.popup-gallery').magnificPopup({
		delegate: 'a',
		type: 'image',
    tLoading: 'Carregant imatge #%curr%…',
    tClose: 'Tancar (Esc)', // Alt text on close button
		mainClass: 'mfp-img-mobile',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
      preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
      tPrev: 'Anterior (Tecla de fletxa esquerra)', // title for left button
      tNext: 'Següent (Tecla de fletxa dreta)', // title for right button
      tCounter: '<span class="mfp-counter">%curr% de %total%</span>'
		},
		image: {
			tError: '<a href="%url%">La imatge #%curr%</a> no s\'ha pogut carregar.',
			titleSrc: function(item) {
				return item.el.attr('title');
			}
		}
	});
});
