$.fn.hasAncestor = function(a) {
  return this.filter(function() {
    return !!$(this).closest(a).length;
  });
};

var QuickNav = function() {
  return {
    init: function() {
      if ($(".quick-nav").length > 0) {
        var i = $(".quick-nav");
        i.each(function() {
          var i = $(this),
            n = i.find(".quick-nav-trigger");
          n.on("click", function(n) {
            n.preventDefault(), i.toggleClass("nav-is-visible")
          })
        }), $(document).on("click", function(n) {
          !$(n.target).is(".quick-nav-trigger") && !$(n.target).is(".quick-nav-trigger span") && !$(n.target).hasAncestor(".modal").length && !$(n.target).closest('.notrigger').length /*&& $('.modal.fade.in').modal('hide')*/ && i.removeClass("nav-is-visible");
        })
      }
    }
  }
}();
QuickNav.init();
